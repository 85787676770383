<template>
  <div class="addTechnological">
<!--    <a-drawer
      :title="update?'编辑':'发布'"
      :visible.sync="drawer"
      @close="turnOff"
      size="60%"
    >-->
    <div class="add-heda" >
      <div class="add-heda-text">发布成果/技术</div>
    </div>
      <div class="technological_content">
        <a-form-model ref="form" :model="form" :rules="rules" label-width="auto"  :label-col="{ span:6 }"  :wrapper-col="{ span: 15}">
          <a-form-model-item label="成果名称" prop="name">
            <a-input placeholder="请输入成果名称" v-model="form.name" ></a-input>
          </a-form-model-item>
          <a-form-model-item label="技术领域:" prop="technicalField">
            <div>
            <a-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
            </a-checkbox>
            <a-checkbox-group v-model="form.technicalField" @change="handleCheckedCitiesChange">
              <a-checkbox v-for="city in cities" :value="city.id" :key="city.id">{{ city.name }}</a-checkbox>
            </a-checkbox-group>
            </div>
          </a-form-model-item>
          <a-form-model-item label="成果类型:" prop="resultsType">
            <a-radio-group v-model="form.resultsType">
              <a-radio v-for="city in resultsTypeList" :value="city.id" :key="city.id">{{ city.name }}</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="成果价格:" required>
            <a-col :span="5">
              <a-form-model-item prop="priceUccess">
                <a-select v-model="form.priceUccess" placeholder="请选择报酬类型">
                  <a-select-option
                    v-for="item in priceUccessList"
                    :key="item.value"
                    :value="item.value">
                    {{item.label}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="17" v-if="form.priceUccess===0" >
              <a-form-model-item prop="price">
                <a-input style="width: 395px;margin-left: 10px" v-model="form.price" type="number" placeholder="请输入技术使用费用"></a-input>
                <span>&nbsp;元</span>
              </a-form-model-item>
            </a-col>
          </a-form-model-item>
          <a-form-model-item label="成果有效期:" prop="periodValidity">
            <a-date-picker
              v-model="form.periodValidity"
              type="date"
              :disabled-date ="disabledDate"
              placeholder="选择日期">
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item  label="图片：" prop="coverPhoto">
                <Uploader @setFileList="setFileList" :limit="1" field="coverPhoto" :img-str="form.coverPhoto"></Uploader>
          </a-form-model-item>
          <a-form-model-item label="成果说明:" prop="resultsShowsThat">
<!--            <div @mouseover="asdasd()">-->
              <Tinymce  @asdasd="asdasd()" ref="Tinymce"  v-model="form.resultsShowsThat" width="100%"></Tinymce>
<!--            </div>-->
          </a-form-model-item>
          <a-form-model-item label="发布单位:" prop="releaseUnit">
            <a-input  placeholder="请输入发布单位" v-model="form.releaseUnit" ></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系人:" prop="contact">
            <a-input placeholder="请输入联系人" v-model="form.contact" ></a-input>
          </a-form-model-item>
          <a-form-model-item label="联系人手机:" prop="contactPhone">
            <a-input placeholder="请输入联系人手机" v-model="form.contactPhone" ></a-input>
          </a-form-model-item>
          <a-form-model-item label="是否发布：" prop="isRelease">
            <a-radio-group v-model="form.isRelease">
              <a-radio  :value="Number(1)">是</a-radio>
              <a-radio  :value="Number(0)">否</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
        <div style="margin: 20px 0 0 20px;text-align: center">
          <a-button  @click="goDemand()" style="margin-right: 20px;">
            返回技术页面
          </a-button>
          <a-button type="primary" @click="submit">
            发布
          </a-button>
          <a-modal v-model="visibleA" title="技术成果发布" @ok="handleOk">
            <p>是否确认发布</p>
            <p></p>
            <p></p>
          </a-modal>
        </div>
      </div>
<!--    </a-drawer>-->
  </div>
</template>

<script>
import Uploader from '../../components/Uploader'
import Tinymce from '../../components/Tinymce'
import {addTechnology} from "../../api/company/technology.js";
import {getDictType} from "../../api/company/dictType";
import moment from "moment";

/*const form = {
  name:null,
  technicalField:[],
  resultsType:null,
  rewardCurrencyType:null,
  priceUccess:null,
  price:null,
  periodValidity:null,
  resultsShowsThat:null,
  releaseUnit:null,
  contact:null,
  contactPhone:null,
  coverPhoto:null,
}*/

export default {
  name: "addTechnological",
  components:{Uploader,Tinymce},
  data(){
    return{
      resultsTypeList:[],
      visibleA:false,
      isIndeterminate:false,
      priceUccessList:[
        {label:'货币',value:0},
        {label:'面议',value:1}
      ],
      form: {
        name:null,
        technicalField:null,
        resultsType:null,
        rewardCurrencyType:null,
        priceUccess:undefined,
        price:null,
        periodValidity:null,
        resultsShowsThat:null,
        releaseUnit:null,
        contact:null,
        contactPhone:null,
        coverPhoto:null,
        isRelease:null,
        state:null,
      },
      cities: [],
      update: false,
      drawer:false,
      checkAll: false,
      rules: {
        isRelease: [
          {required: true, message: '请选择是否发布', trigger: 'change'}
        ],
        name: [{required: true, message: '请输入成果名称', trigger: 'blur'}],
        technicalField: [
          {required: true, message: '请选择技术领域', trigger: 'change'}
        ],
        resultsType: [
          {required: true, message: '请选择成果类型', trigger: 'change'}
        ],
        priceUccess: [
          {required: true, message: '请选择报酬类型', trigger: 'change'}
        ],
        price: [
          {required: true, message: '请输入报酬金额', trigger: 'blur'}
        ],
        periodValidity: [
          {required: true, message: '请选择成果有效期', trigger: 'change'}
        ],
        coverPhoto: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
        resultsShowsThat: [
          {required: true, message: '请输入成果说明', trigger: 'change'}
        ],
        releaseUnit: [
          {required: true, message: '请输入发布单位', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        contactPhone: [
          {required: true, message: '请输入联系人手机', trigger: 'blur'}
          ,{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号！' ,trigger: 'blur'}
        ]
      }
    }
  },
  methods:{
    asdasd(){
      this.$refs.form.validateField( 'resultsShowsThat')
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
  async  handleOk() {
      this.form.technicalField=this.form.technicalField.join(",");
     await addTechnology(this.form).then(res=>{
        //console.log(res);
        this.$message.success("发布成功")
        this.$router.go(-1);
      })
    this.visibleA = false;
    this.turnOff();
    },
    goDemand(){
      this.$router.go(-1);
    },
    submit(){
      this.$refs.form.validate( async valid =>{
        if (valid) {
          this.visibleA = true;
        }
      })
    },
    handleCheckAllChange() {
      this.form.technicalField = this.checkAll ? this.cities.map(item => {
        return item.id
      }) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      this.form.technicalField = value
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    turnOff() {
      this.$refs.form.resetFields()
    },
    setFileList(obj) {
      this.form[obj.field] = obj.img_str
      this.$refs.form.validateField( 'coverPhoto')
    },
  },
  mounted() {
    getDictType(30).then(res=>{
      this.cities=res.data;
    });
    getDictType(5).then(res=>{
      this.resultsTypeList=res.data;
    });
  }
}
</script>

<style lang="scss" scoped>
.addTechnological{
  width: 1100px;
  height: 100%;
  padding-bottom: 20px;
  background: #FFFFFF;
  border: 2px solid #EFEFEF;
  margin-left: 110px;
  .technological_content {
    margin-top: 20px;
    // padding: 20px;
    /*  overflow-y: scroll;
      position: relative;*/
    .form-input{
      width: 435px;
    }
    .form-input :hover{
      border-color:#ff9a02;
    }
  }
  .add-heda{
    width: 1098px;
    height: 50px;
    background: #F9F9F9;
    border-bottom: 2px solid #EFEFEF;
    border-right: 2px solid #EFEFEF;
    .add-heda-text{
      width: 167px;
      height: 21px;
      font-size: 21px;
      font-family: Hiragino Sans GB !important;
      font-weight: normal;
      color: #000000;
      line-height: 45px;
      margin-left: 27px;
      font-weight: 600;
    }
  }
}
</style>
<style lang="scss" >
.ant-checkbox-wrapper:first-child{
  padding-left: 10px;
}
.addTechnological{
  .ant-input {
    width: 545px;
    height: 46px;
  }
  .ant-select-selection--single {
    height: 44px;
    width: 145px;
    margin-right: 20px;
    padding-top: 6px;
    border: 1px solid #E1E1E1;
  }
  .ant-select:focus {
    .ant-select-selection--single {
      border-color:#ff9a02;
      box-shadow:0px 0px 0px #ff9a02;
    }

  }
  .ant-select:hover{
    .ant-select-selection--single {
      border-color:#ff9a02;
    }
  }
  .ant-form-item-required{
    color: #666666 !important;
  }
}
</style>

